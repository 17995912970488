import React from 'react';

function Footer() {
  return (
    <div>
      {/* Footer Section Start */}
      <footer>
        {/* Footer area Start */}
        <div className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="widget">
                  <h3 className="widget-title"><i className="fa fa-comments" /> Contact Us</h3>
                  <ul className="menu-useful-links">
                    <li><i className="fa fa-home" /> CASA TIZOC</li>
                    <li>Cerca de Via Santa Maria, 63</li>
                    <li>56126 Pisa, Italy</li>
                    <li><i className="fa fa-envelope-o" /><a href="mailto:casatizoc@gmail.com"> casatizoc@gmail.com</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="widget">
                  <h3 className="widget-title"><i className="fa fa-clock-o" /> Opening Hours</h3>
                  <ul className="menu-useful-links">
                    <li><i className="fa fa-cutlery" /> Tue-Sun 12:00-15:00 & 18:30-23:00</li>
                  </ul>
                </div><br />
                <div>
                  <audio src="audio/Son_de_la_negra.mp3" preload="auto" controls autoPlay/>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-xs-12">
                <div className="widget">
                  <h3 className="widget-title"><i className="fa fa-thumbs-o-up" /> Stay Connected</h3>
                  <div className="social-icon">
                    {/*<a href="#"><i className="fa fa-twitter" /></a>*/}
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/casatizoc/"><i className="fa fa-facebook" /></a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/casatizoc/"><i className="fa fa-instagram" /></a>
                    {/*<a href="#"><i className="fa fa-youtube" /></a>
                    <a href="#"><i className="fa fa-google-plus" /></a>*/}
                  </div>
                  <form className="subscribe-form" action="mailto:casatizoc@gmail.com">
                    <input className="form-control" type="email" placeholder="Enter Your Email" value="" name="email" />
                    <div className="sb-button">
                      <i className="fa fa-message" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer area End */}
        {/* Copyright Start  */}
        <div id="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p>© Copyright 2021 Casa Tizoc - Designed &amp; Developed by <a href="https://flautisimo.github.io/portfolio/" rel="noreferrer" target="_blank"><strong>Flautisimo</strong></a></p>
              </div>

            </div>
          </div>
        </div>
        {/* Copyright End */}
      </footer>
      {/* Footer Section End */}
      <a href="#" className="back-to-top" style={{ display: 'block' }}>
        <i className="fa fa-angle-up" />
      </a>
    </div>
  )
};

export default Footer;