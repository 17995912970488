import React from 'react';
import { Link, withRouter } from 'react-router-dom';

function Navigation(props) {
  return (
    <div>
      {/* Header Logo and Menu Start */}

      <div className="navbar navbar-default navbar-fixed-top affix" role="navigation" data-spy="affix" data-offset-top={50}>
        <div className="container">
          <div className="navbar-header">
            <div className="slicknav_menu">
              <span className="slicknav_btn slicknav_collapsed" aria-haspopup="true" tabIndex={0} style={{}}>
                <span className="slicknav_menutxt" /><span className="slicknav_icon slicknav_no-text"><span className="slicknav_icon-bar" /><span className="slicknav_icon-bar" /><span className="slicknav_icon-bar" /></span></span>
              <ul className="slicknav_nav slicknav_hidden" style={{ display: 'none' }} aria-hidden="true" role="menu">
                <li className="slicknav_collapsed slicknav_parent">
                  {/*<span role="menuitem" aria-haspopup="true" tabIndex={-1} className="slicknav_item slicknav_row"><Link className="active" to="/" tabIndex={-1}>Home</Link>
                      <span className="slicknav_arrow"><i className="fa fa-angle-right" />
                      </span>
                    </span>*/}
                  <ul className="dropdown slicknav_hidden" role="menu" style={{ display: 'none' }} aria-hidden="true">
                    <li><Link to="/" role="menuitem" tabIndex={-1}>Home</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="/menu" role="menuitem" tabIndex={-1}>Menu</Link>
                </li>
                {/*<li className="slicknav_collapsed slicknav_parent">
                    <span role="menuitem" aria-haspopup="true" tabIndex={-1} className="slicknav_item slicknav_row">
                      <a href="#" tabIndex={-1}>Reservation</a>
                      <span className="slicknav_arrow"><i className="fa fa-angle-right" />
                      </span>
                      </span><ul className="dropdown slicknav_hidden" role="menu" style={{ display: 'none' }} aria-hidden="true">
                      <li><a href="reservation-form-1.html" role="menuitem" tabIndex={-1}>Reservation Form 1</a></li>
                      <li><a href="reservation-form-2.html" role="menuitem" tabIndex={-1}>Reservation Form 2</a></li>
                    </ul>
                  </li>*/}
                {/*<li className="slicknav_collapsed slicknav_parent"><span role="menuitem" aria-haspopup="true" tabIndex={-1} className="slicknav_item slicknav_row">
                    <a href="#" tabIndex={-1}>Features</a>
                    <span className="slicknav_arrow"><i className="fa fa-angle-right" /></span></span><ul className="dropdown slicknav_hidden" role="menu" style={{ display: 'none' }} aria-hidden="true">
                      <li><a href="about.html" role="menuitem" tabIndex={-1}>About Us</a></li>
                      <li><a href="portfolio.html" role="menuitem" tabIndex={-1}>Gallery</a></li>
                      <li><a href="team.html" role="menuitem" tabIndex={-1}>Team</a></li>
                      <li><a href="404.html" role="menuitem" tabIndex={-1}>404 Page</a></li>
                    </ul>
                  </li>*/}
                <li>
                  <Link to="/contact" role="menuitem" tabIndex={-1}>Contact</Link>
                </li>
                {/*<li className="slicknav_collapsed slicknav_parent"><span role="menuitem" aria-haspopup="true" tabIndex={-1} className="slicknav_item slicknav_row"><a href="blog.html" tabIndex={-1}>Blog</a>
                  <span className="slicknav_arrow"><i className="fa fa-angle-right" /></span></span><ul className="dropdown slicknav_hidden" role="menu" style={{ display: 'none' }} aria-hidden="true">
                    <li><a href="blog.html" role="menuitem" tabIndex={-1}>Page With right sidebar</a></li>
                    <li><a href="left-sidebar.html" role="menuitem" tabIndex={-1}>Page With left sidebar</a></li>
                    <li><a href="single-post.html" role="menuitem" tabIndex={-1}>Blog single post</a></li>
                  </ul>
                </li>*/}
              </ul>
            </div>
            {/* start Toggle Nav For Mobiles */}
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navigation">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <Link to="/" className="navbar-brand"><img src="img/Casa-Tizoc-small.png" alt="" /></Link>
          </div>
          {/*  Start Navigation*/}
          <div className="navbar-collapse collapse" id="navigation">
            {/* Top Search Start */}
            {/*<ul className="right cart-search">
                <li className="has-form">
                  <div id="top-search">
                    <div className="header-search-wrap">
                      <div className="dropdown header-search">
                        <a href="#" id="search-button" className="dropdown-toggle" data-toggle="dropdown" role="button">
                          <i className="icon-search" />
                        </a>
                        <div className="dropdown-menu" aria-labelledby="Search-button">
                          <form action className="td-search-form" method="get" role="search">
                            <div className="head-form-search">
                              <input id="td-header-search" className="form-control" type="text" autoComplete="off" name="s" defaultValue placeholder="Search" />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="cart">
                <a href="#">
                  <i className="fa fa-shopping-cart" />
                  <span className="cart-count">0</span>
                </a>
                </li>
              </ul>*/}
            {/* Top Search End */}
            {/* Start Navigation List */}
            <ul className="nav navbar-nav navbar-right">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/menu">Menu</Link></li>
              {/*<li><a href="#">Reservation</a></li>
                <li><a href="#">Features</a>
                <ul className="dropdown">
                  <li><a href="about.html">About Us</a></li>
                  <li><a href="portfolio.html">Gallery</a></li>
                  <li><a href="team.html">Team</a></li>
                  <li><a href="404.html">404 Page</a></li>
                </ul>
                </li>*/}
              <li><Link to="/contact">contact</Link></li>
              {/*<li><a href="blog.html">Blog</a>
                <ul className="dropdown">
                  <li><a href="blog.html">Page With right sidebar</a></li>
                  <li><a href="left-sidebar.html">Page With left sidebar</a></li>
                  <li><a href="single-post.html">Blog single post</a></li>
                </ul>
                </li>*/}
            </ul>
          </div>
          {/* Mobile Menu Start */}
          <ul className="wpb-mobile-menu">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/menu">Menu</Link></li>
            {/*<li><a href="#">Reservation</a>
              <ul className="dropdown">
                <li><a href="reservation-form-1.html">Reservation Form 1</a></li>
                <li><a href="reservation-form-2.html">Reservation Form 2</a></li>
              </ul>
              </li>
              <li><a href="#">Features</a>
              <ul className="dropdown">
                <li><a href="about.html">About Us</a></li>
                <li><a href="portfolio.html">Gallery</a></li>
                <li><a href="team.html">Team</a></li>
                <li><a href="404.html">404 Page</a></li>
              </ul>
              </li>*/}
            <li><Link to="/contact">contact</Link></li>
            {/*<li><a href="blog.html">Blog</a>
              <ul className="dropdown">
                <li><a href="blog.html">Page With right sidebar</a></li>
                <li><a href="left-sidebar.html">Page With left sidebar</a></li>
                <li><a href="single-post.html">Blog single post</a></li>
              </ul>
              </li>*/}
          </ul>
          {/* Mobile Menu End */}
        </div>
      </div>
      {/* Header Logo and Menu End */}

    </div>
  );
}

export default withRouter(Navigation);