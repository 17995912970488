import React from 'react';
import { Link, withRouter } from 'react-router-dom';

function Navigation2(props) {
  return (
    <div>
      <nav className="navbar-default navbar-fixed-top affix">
        <div className='container'>
          <Link to="/" className="navbar-brand"><img src="img/Casa-Tizoc-small.png" alt="" /></Link>
          <label for="drop" className="toggle"><i className="fa fa-bars" aria-hidden="true"></i></label>
          <input type="checkbox" id="drop" />
          <ul className="nav">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/menu">Menu</Link></li>
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
      </nav>
    </div>
  )
};

export default withRouter(Navigation2);