import React from 'react';

function Home() {
  return (
    <div>
      {/*Main Slider Starts*/}
      <div id="carousel-area">
        <div className="touch-slider owl-carousel owl-theme" style={{ opacity: 1, display: 'block' }}>
          <div className="owl-wrapper-outer">
            <div className="owl-wrapper" style={{ width: '11436px', left: '0px', display: 'block', transition: 'all 800ms ease 0s', transform: 'translate3d(-1906px, 0px, 0px)' }}>
              <div className="owl-item" style={{ width: '1906px' }}>
                <div className="item">
                  <img className="slider-img" src="img/slider/image-1.jpg" alt="" />
                  {/*<div className="carousel-caption">
                    <div className="slider-content">
                      <img src="img/top.png" alt="" />
                      <h1>Welcome to Casa Tizoc</h1>
                      <p className="description">We offer the best meals at the best value</p>
                      <img src="img/bottom.png" alt="" />
                    </div>
                    <div className="bottom-arrow">
                      <p>Explore recipes</p>
                      <a href="#recipes"> <i className="fa fa-chevron-down" /></a>
                    </div>
                  </div>*/}
                </div>
              </div>
              <div className="owl-item" style={{ width: '1906px' }}>
                <div className="item">
                  <img className="slider-img" src="img/slider/restaurante-exterior-banner1.jpg" alt="" />
                  <div className="carousel-caption">
                    <div className="slider-content">
                      <img src="img/top.png" alt="" />
                      <h1>Casa Tizoc</h1>
                      <p className="description">Restaurante Mexicano</p>
                      <img src="img/bottom.png" alt="" />
                    </div>
                    {/*<div className="bottom-arrow">
                      <p>Explore recipes</p>
                      <a href="#recipes"> <i className="fa fa-chevron-down" /></a>
                    </div>*/}
                  </div>
                </div>
              </div>
              {/*<div className="owl-item" style={{ width: '1906px' }}>
                <div className="item">
                  <img className="slider-img" src="img/slider/image-3.jpg" alt="" />
                  <div className="carousel-caption">
                    <div className="slider-content">
                      <img src="img/top.png" alt="" />
                      <h1>Make a Reservation</h1>
                      <p className="description">We're always open call us to confirm</p>
                      <img src="img/bottom.png" alt="" />
                    </div>
                    <div className="bottom-arrow">
                      <p>Explore recipes</p>
                      <a href="#recipes"> <i className="fa fa-chevron-down" /></a>
                    </div>
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
          {/*<div className="owl-controls clickable">
            <div className="owl-buttons">
              <div className="owl-prev"><i className="fa fa-chevron-left" />
              </div>
              <div className="owl-next"><i className="fa fa-chevron-right" />
              </div>
            </div>
          </div>*/}
        </div>
      </div>
      {/*Main Slider Ends*/}
      {/*About Us Section Starts*/}
      <section id="about" className="general-row">
        <div className="container">
          <div className="row about-wrapper">
            <div className="col-md-6 col-sm-6 col-xs-12 text-content">
              <div className="sce-title">
                <h2>About Us</h2>
                {/*<p>Restaurante Mexicano</p>*/}
              </div>
              <div className="sce-text">
                <p>
                We are art, culture and excellence. A restaurant that praises the best of Mexico, reflecting in its exquisite dishes our traditional flavors and aromas emblematic of every corner of our country, with selected ingredients, presenting them in an environment full of history that makes us unique. Music, decoration and flavors are harmoniously intertwined to offer the best of Mexico to our guests.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 left-image">
              <div className="image">
                <img src="img/restaurant/Casa Tizoc_Hero.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*About Us Section Ends*/}
    </div>
  )
}

export default Home;