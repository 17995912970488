import { map } from 'jquery';
import React from 'react';

function Contact() {
  return (
    <div>
      {/* Top  main section Start */}
      <section className="top-main">
        <div className="top-site-images">
          <img src="/img/bg/Cocktail-banner.jpg" alt="" />
        </div>
        <div className="page-title-wrapper">
          <div className="container">
            <div className="banner-wrapper">
              <h1 className="heading">Contact</h1>
              <h3 className="description"><i>Feel free to get in touch with us.<br />We would love to hear from you!</i></h3>
            </div>
          </div>
        </div>
      </section>
      {/* Top  main section End */}
      {/* Page Section Start */}
      <div className="page-container">
        {/* Content Start */}
        <div id="content">
          <div className="container">
            <div className="row">
              {/* Contact Info Start */}
              <div className="contact-info-wrapper clearfix">
                <div className="col-md-4">
                  <div className="contact-item-wrapper">
                    <div className="icon">
                      <i className="fa fa-phone-square" />
                    </div>
                    <p>Phone: +39 351 538-6404</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-item-wrapper bl">
                    <div className="icon">
                      <i className="fa fa-map-marker" />
                    </div>
                    <p>Cerca de Via Santa Maria, 63<br />56126 Pisa, Italia</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-item-wrapper">
                    <div className="icon">
                      <i className="fa fa-envelope" />
                    </div>
                    <p><a href="mailto:casatizoc@gmail.com">casatizoc@gmail.com</a></p>
                  </div>
                </div>
              </div>
              {/* Contact Info End */}
              <div className="col-md-8 col-md-offset-2 contact-form">
                {/* Start Contact Form */}
                <form id="contactForm" className="contact-form" data-toggle="validator">
                  <div className="form-group">
                    <div className="controls">
                      <input type="text" id="name" className="form-control" placeholder="Name" required data-error="Please enter your name" />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="controls">
                      <input type="email" className="email form-control" id="email" placeholder="Email" required data-error="Please enter your email" />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="controls">
                      <input type="text" id="msg_subject" className="form-control" placeholder="Subject" required data-error="Please enter your message subject" />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="controls">
                      <textarea id="message" rows={7} placeholder="Message" className="form-control" required data-error="Write your message" defaultValue={""} />
                      <div className="help-block with-errors" />
                    </div>
                  </div>
                  <button type="submit" id="submit" className="btn btn-common btn-sn"> Send Message</button>
                  <div id="msgSubmit" className="h3 text-center hidden" />
                  <div className="clearfix" />
                </form>
                {/* End Contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* Content End */}
      </div>
      {/* PageSection End */}
      {/*<div id="google-map"></div>*/}
      <div style={{ maxWidth: '100%', overflow: 'hidden', color: 'red', width: '1906px', height: '400px' }}>
        <div id="canvasfor-googlemap" style={{ height: '100%', width: '100%', maxWidth: '100%' }}>
          <iframe title={map} style={{ height: '100%', width: '100%', border: 0 }} src="https://www.google.com/maps/embed/v1/place?q=casa+tizoc,+pisa,+italy&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" frameBorder={0} />
        </div>
        <a className="codefor-googlemap" rel="nofollow" href="https://www.embed-map.com" id="authorizemaps-data">Map for Casa Tizoc</a>
        <style dangerouslySetInnerHTML={{ __html: "#canvasfor-googlemap .text-marker{}.map-generator{max-width: 100%; max-height: 100%; background: none;" }} />
      </div>
    </div>
  )
};

export default Contact;