import React from 'react';

function Menu() {
  return (
    <div>
      <section className="top-main">
        <div className="top-site-images">
          <img src="img/bg/Restaurant1-banner.jpg" alt="" />
        </div>
        <div className="page-title-wrapper">
          <div className="container">
            <div className="banner-wrapper">
              <h1 className="heading">Our Menu</h1>
              <h4 className="description"><i>The pleasure of a banquet is not measured by the abundance of delicacies,<br /> but by the reunion of friends and their conversations.</i></h4>
            </div>
          </div>
        </div>
      </section>

      <div className="page-container">
        <div className="container">
          <h1 className="menu-title">APPETIZERS</h1><br />
          <div className="row">
            {/* Left Menu */}
            <div className="col-md-6 col-sm-6 col-sx-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-01.jpg" alt="" />
                </div>*/}
                  {/*<div className="rating">
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                  </div>*/}
                <div className="menu-right">
                  <span className="price">€ 8,50</span>
                  <h4>TRADITIONAL MEXICAN GUACAMOLE</h4>
                  <p>Fresh avocado, tomato, cilantro, onion, chilli, served with totopos (homemade corn chips).</p>
                </div>
              </div>
            </div>
            {/* Right Menu */}
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-02.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 7,50</span>
                  <h4>REFRIED BEANS</h4>
                  <p>Beans flavored with mexican spices, served with sour cream and cheese, accompanied with totopos (homemade corn chips) and pico de gallo sauce (tomato, fresh chilli, cilantro, onion and lemon).</p>
                </div>
              </div>
            </div>
            {/* Left Menu */}
            <div className="col-md-6 col-sm-6 col-sx-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-03.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 8,00</span>
                  <h4>SOPESITOS</h4>
                  <p>Trio of sopes (small corn tortillas with raised edges), served with chorizo (mexican sausage) and potatoes, cream of beans, lettuce, cream and cheese, accompanied with ours house sauces</p>
                </div>
              </div>
            </div>
            {/* Right Menu */}
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 8,00</span>
                  <h4>VEGETARIAN SOPESITOS</h4>
                  <p>Trio of sopes served with courgettes, courgette's flowers, onion and corn, vegetable cream, beans cream, accompanied with mexican house sauces.</p>
                </div>
              </div>
            </div>
            {/* Left Menu */}
            <div className="col-md-6 col-sm-6 col-sx-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-05.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 9,00</span>
                  <h4>CEVICHE TOSTADA, SINALOA STYLE</h4>
                  <p>Marinated white fish with lemon and mexican spices, served on crispy corn tortilla with tomato, cilantro, onion, avocado, cucumber, black sauce of the house and chipotle-mayo. <strong>Origin:</strong><i> Sinaloa, northwest.</i></p>
                </div>
              </div>
            </div>
            {/* Right Menu */}
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-06.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 5,00</span>
                  <h4>QUESADILLA</h4>
                  <p>Flour tortilla filled with melted cheese served with some spicy mexican sauces.</p>
                </div>
              </div>
            </div>
            {/* Left Menu */}
            <div className="col-md-6 col-sm-6 col-sx-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-07.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 7,50</span>
                  <h4>QUESADILLA of COCHINITA</h4>
                  <p>Flour tortilla, stuffed with pulled pork meat seasoned with mexican spices, with melted cheese.<br /><strong>Origin of Cochinita Pibil:</strong><i> Yucatan, southeast. Century XVI.</i></p>
                </div>
              </div>
            </div>
            {/* Right Menu */}
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-08.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 7,50</span>
                  <h4>QUESADILLA of CHICKEN</h4>
                  <p>Flour tortilla filled with grilled chicken, served with cilantro, onion, sauces without picante and melted cheese.</p>
                </div>
              </div>
            </div>
            {/* Left Menu */}
            <div className="col-md-6 col-sm-6 col-sx-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-05.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 7,50</span>
                  <h4>GRINGA</h4>
                  <p>Flour tortilla filled with melted cheese and meat 'Al pastor', cilantro, onion and pineapple.</p>
                </div>
              </div>
            </div>
            {/* Right Menu */}
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 9,50</span>
                  <h4>NACHOS al PASTOR</h4>
                  <p>Beans cream, pico de gallo, sour cream, cheese melted, avocado accompanied with spicy sauce.</p>
                </div>
              </div>
            </div>
            <div className="row"></div><br />
            <h1 className="menu-title">TACOS</h1><br />
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">2 x € 6,00</span>
                  <h4>Al pastor</h4>
                  <p>Marinated pork with mexican spices served with cilantro, onion and pineapple.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">2 x € 6,00</span>
                  <h4>Carne asada</h4>
                  <p>Grilled beef, served with cilantro, onion, sauces without picante, avocado in soft tortillas.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">2 x € 6,00</span>
                  <h4>Pollo a la Plancha</h4>
                  <p>Grilled chicken served with cilantro, onion, avocado, sauces without picante and soft tortilla.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">2 x € 6,00</span>
                  <h4>Cochinita pibil</h4>
                  <p>Pulled pork topped with mexican spices, marinated red onion in lemon, avocado served in soft tortillas.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">2 x € 6,00</span>
                  <h4>Chorizo con papas</h4>
                  <p>Mexican sausage cooked in a pan with potatoes served with sour cream, in soft tortillas.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">5 x € 14,50</span>
                  <h4>TACOS MIX</h4>
                  <p>Five differents tacos: Carne asada, Grilled chicken, Al Pastor, Chorizo con papas, Cochinita, in soft tortillas.</p>
                </div>
              </div>
            </div>
            <div className="row"></div><br />
            <h1 className="menu-title">MAIN DISHES</h1><br />
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 17,00</span>
                  <h4>POLLO ENCACAHUATADO</h4>
                  <p>Chicken breast in peanut and spiced mexican sauce, served with white rice, tortillas, and cream of beans.<strong> Origin of Encacahuatado:</strong><i> Central Mexico. Century XIX.</i></p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item-large">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 18,00</span>
                  <h4>TURKEY IN MOLE</h4>
                  <p>Homemade Mole Sauce (chili peppers and mexican spices, may contain chunks of almonds, peanuts, sesame, and wheat flour) 180 grs of turkey breast, served with mexican white rice and bean cream.<br /><strong> Origin of Mole:</strong><i> Central Mexico, Prehispanic age. Offered in ceremonies as a tribute to the gods.</i></p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 12,00</span>
                  <h4>BURRITO CULICHI</h4>
                  <p>Grilled Angus beef, cream of beans, lettuce, guacamole, sauteed onion with butter, pico de gallo sauce served with nachos and spicy sauces, in flour tortilla.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 12,00</span>
                  <h4>BURRITO AL PASTOR</h4>
                  <p>Flour tortilla, fried pork topped with mexican spices, stuffed with melted cheese.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 12,00</span>
                  <h4>BURRITO DI POLLO</h4>
                  <p>Grilled chicken, cream of beans, lettuce, guacamole, sauteed onions with butter, pico de gallo sauce served with nachos and spicy sauces in flour tortilla.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 16,00</span>
                  <h4>TACOS DORADOS DI POLLO</h4>
                  <p>Corn tortillas filled with chicken, served with lettuce, sour cream, cottage cheese, avocado, rice, cream of beans.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item-large">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 25,00</span>
                  <h4>COLHUA TOWER</h4>
                  <p>Exclusive dish of the Mexican region of Sinaloa, based on special mixed seafood, cooked and marinated in the juice of lemon; Scallops, shrimps, octopus and white fish, avocado, cucumber, red onion, Mexican spices, chipotlemaio, served a tower shape accompanied by the special black sauce of the house and homemade corn chips.<br /><strong> Origin:</strong><i> Culiacan, Sinaloa. Colhua, was the name of the tribe that inhabited this region in prehispanic times.</i></p>
                </div>
              </div>
            </div>
            <div className="row"></div><br />
            <h1 className="menu-title">DESSERTS</h1><br />
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 7,50</span>
                  <h4>CUBILETE RELLENO</h4>
                  <p>Chef's recipe, pie filled with cheese, covered with caramelized apple and tequila, jamaica's flowers scented at anise, cinnamon and cloves, (we recommend you tasting with a shot of Tequila Anejo).</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 6,00</span>
                  <h4>PASTEL IMPOSIBLE</h4>
                  <p>Double dough cake, half pudding (flan), half chocolate, and sweet milk.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item-large">
                {/*<div className="menu-left">
                  <img src="img/menu/img-04.jpg" alt="" />
                </div>*/}
                <div className="menu-right">
                  <span className="price">€ 5,50</span>
                  <h4>FLAN DE ELOTE</h4>
                  <p>Pudding with corn, poached with Rompope (liqueur egg).</p>
                </div>
              </div>
            </div>
            <div className="row"></div><br />
            <h1 className="menu-title">DRINKS</h1><br />
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item-with-picture">
                <div className="menu-left">
                  <img src="img/menu/Tequila_patron_small.jpg" alt="" />
                </div>
                <div className="menu-right">
                  <h4>OUR TEQUILA & MEZCAL (shot)</h4>
                  <p>Don Julio blanco ... €5.00</p>
                  <p>Don Julio reposado ... €5.00</p>
                  <p>Jose Cuervo plata silver ... €3.00</p>
                  <p>Jose Cuervo especial ... €3,00</p>
                  <p>Corralejo Añejo ... €5.00</p>
                  <p>Patron ... €5.00</p>
                  <p>Cazadores ... €4.00</p>
                  <p>Mezcal con Gusano ... €4.00</p>
                  <p>Monte Albán ... €4.00</p>
                  <p>Sauza Gold ... €3.00</p>
                  <p>Espolón ... €3,00</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <h4>RED WINE (bottle)</h4>
                  <p>Castelfalfi Toscana ... €15</p>
                  <p>Bolgheri ... €25</p>
                  <p>Chianti ... €15</p>
                  <p>Chianti Riserva ... €20</p>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <h4>WHITE WINE (bottle)</h4>
                  <p>Vermentino ... €15</p>
                  <p>Prosecco ... €15</p>
                  <p>Ribolla Gialla ... €20</p>
                  <p>Franciacorta ... €25</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="menu-item-with-picture">
                <div className="menu-left">
                  <img src="img/menu/Cerveza_Modelo_small.jpg" alt="" />
                </div>
                <div className="menu-right">
                  <h4>MEXICAN BEER</h4>
                  <p>Modelo Especial ... €6,00</p>
                  <p>Negra Modelo ... €6,00</p>
                  <p>Bohemia Viena ... €6,00</p>
                  <p>Bohemia Clara ... €6,00</p>
                  <p>Pacifico ... €6,00</p>
                  <p>Corona ... €4,50</p>
                  <p>Sol ... €5,00</p>
                </div>
              </div>
            </div>
            <div className="row"></div><br />
            <h1 className="menu-title">COCKTAILS</h1><br />
            <h3 className="menu-subtitle"><i>Specialties</i></h3>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <span className="price">€ 10,00</span>
                  <h4>BLUE FIRE</h4>
                  <p>Blue color cocktail served on fire. <br />Tequila <strong>Corralejo Añejo </strong>100% premium agave, lemon, contreau and curasao.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <span className="price">€ 10,00</span>
                  <h4>DON GOYO VULCAN</h4>
                  <p>Served steaming, soft and full bodied on the palate but with the classic dry flavor, finishing with aroma of spicy notes and dried fruit. <i>Absolut Vodka, Rum Pampero Especial, Grenadine, pomegranate juice, lemon.</i></p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <span className="price">€ 10,00</span>
                  <h4>ARCOIRIS</h4>
                  <p>5-color cocktail reminiscent of a rainbow, drinking through its different layers, you will feel the taste of alcohol, citrus and sweet. <br /><i><strong>Vodka Ice</strong>, orange juice, grenadine and blue curasao.</i></p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <span className="price">€ 10,00</span>
                  <h4>24K</h4>
                  <p>Cinnamon on fire and gold 24k. <br /><i>Dark rum <strong>Don Papa 10</strong>, liquore of chocolate Kahlua, orange, spicy and cinnamon.</i></p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <span className="price">€ 10,00</span>
                  <h4>SMOKE GIN TONIC</h4>
                  <p>Finest Gin with hints of orange, berries juniper, basil, cardamom, coriander, lemon, rosemary and thyme, served with a smoke bubble. <br /><i><strong>Gin Mare</strong>, Tonic, orange, rosemary.</i></p>
                </div>
              </div>
            </div>
            <div className="row"></div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <h4>CHELADA</h4>
                  <p>Glass with salt, lemon and beer of your choice; <br />Sol | Corona | Victoria ... €6,50<br />Negra Modelo | Modelo Especial | Pacifico ... €7,50</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <h4>MICHELADA</h4>
                  <p>Glass with salt, lemon, Mexican sauces, tajin, and beer of your choice; <br />Sol | Corona | Victoria ... €8,00<br />Negra Modelo | Modelo Especial | Pacifico ... €8,50</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <h4>MICHELADA ESPECIAL</h4>
                  <p>Jar (500 ml) with salt, lemon, Mexican sauces, tajin, chamoy, clamato and stick of tamarind, beer of your choice; <br />Sol | Corona | Victoria | Negra Modelo | Modelo Especial | Pacifico ... €9,50</p>
                </div>
              </div>
            </div>
            <div className="row"></div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <h4>MEXICAN</h4>
                  <ul>
                    <li><p><strong>PALOMA ... €6,00</strong><br /><i>Tequila, grapefruit, lemon, salt, mango.</i></p></li>
                    <li><p><strong>ACAPULCO ... €7,00</strong><br /><i>Tequila, pineapple, rum, grapefruit.</i></p></li>
                    <li><p><strong>TEQUILA SUNRISE ... €7,00</strong><br /><i>Tequila, orange, grenadine.</i></p></li>
                    <li><p><strong>CASA TIZOC ... €7,00</strong><br /><i>Rum, lime, guava, morita pepper.</i></p></li>
                    <li><p><strong>CHARRO NEGRO ... €7,00</strong><br /><i>Rum, lemon, coke.</i></p></li>
                    <li><p><strong>MARGARITAS</strong><br /><i>(Tequila, Triple Sec, Limon, Salt)</i><br />CLASSICO | MARACUJA | PAPAYA | MANGO | STRAWBERRY | CASA TIZOC<br /><strong>Normal €7,00 / Frozen €8,00</strong></p></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <h4>CLASSIC</h4>
                  <ul>
                    <li><p><strong>PIÑA COLADA ... €7,00</strong><br /><i>Pineapple, rum, coconut milk.</i></p></li>
                    <li><p><strong>MOJITO ... €7,00</strong><br /><i>Rum, lemon, mint, soda.</i></p></li>
                    <li><p><strong>MOJITO MARACUJA ... €7,00</strong><br /><i>Rum, lemon, mint, soda, passion fruit.</i></p></li>
                    <li><p><strong>WHITE RUSSIAN ... €7,00</strong><br /><i>Vodka, Kalhua, cream of milk.</i></p></li>
                    <li><p><strong>RUM COOLER ... €7,00</strong><br /><i>Rum, ginger ale, soda.</i></p></li>
                    <li><p><strong>MOSCOW MULE ... €7,00</strong><br /><i>Vodka, lime, ginger beer.</i></p></li>
                    <li><p><strong>AMERICANO ... €7,00</strong><br /><i>Bitter, red vermouth.</i></p></li>
                    <li><p><strong>NEGRONI ... €7,00</strong><br /><i>Red vermouth, gin, Campari bitters.</i></p></li>
                    <li><p><strong>WRONG NEGRONI ... €7,00</strong><br /><i>Red vermouth, Campari bitter, sparkling wine.</i></p></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <h4>ALCOHOLFREE</h4>
                  <ul>
                    <li><p><strong>VIRGIN MOJITO ... €6,00</strong><br /><i>Lemon, brown sugar, mint, ginger ale.</i></p></li>
                    <li><p><strong>VIRGIN COLADA ... €6,00</strong><br /><i>Pineapple juice, coconut milk, fresh annas.</i></p></li>
                    <li><p><strong>VIRGIN PUNCH ... €6,00</strong><br /><i>Green apple, lemon, grenadine and sprite juice.</i></p></li>
                    <li><p><strong>CARIBE ... €6,00</strong><br /><i>Pineapple, papaya, mango and strawberry juice.</i></p></li>
                    <li><p><strong>CANCUN ... €6,00</strong><br /><i>Orange, grenadine, strawberry and annas.</i></p></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-3 col-xs-6">
              <div className="menu-item-large">
                <div className="menu-right">
                  <h4>FROZEN</h4>
                  <ul>
                    <li><p><strong>DAIQUIRI ... €8,00</strong><br /><i>Rum, lemon, sugar syrup.</i></p></li>
                    <li><p><strong>BANANA DAIQUIRI ... €8,00</strong><br /><i>Rum, lime, bannana syrup.</i></p></li>
                    <li><p><strong>STRAWBERRY DAIQUIRI ... €8,00</strong><br /><i>Rum, lime, strawberry syrup.</i></p></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-6 col-xs-12">
              <div className="menu-item-large">
                <h4>WORLDWIDE</h4>
                <div className="menu-column-left">
                  <ul>
                    <li><p><strong>SPRITZ VENEZIANO ... €7,00</strong><br /><i>Aperol, soda, Prossecco, orange.</i></p></li>
                    <li><p><strong>SEX ON THE BEACH ... €7,00</strong><br /><i>Vodka, peach vodka, orange juice and cranberry juice.</i></p></li>
                    <li><p><strong>ROSSINI ... €7,00</strong><br /><i>Strawberry juice, sparkling wine.</i></p></li>
                    <li><p><strong>BELLINI ... €7,00</strong><br /><i>Peach juice, sparkling wine.</i></p></li>
                    <li><p><strong>COSMOPOLITAN ... €7,00</strong><br /><i>Lemon vodka, contreau, lime, juice with blueberry.</i></p></li>
                    <li><p><strong>CAIPIROSKA ... €7,00</strong><br /><i>Vodka, lime, liquid sugar.</i></p></li>
                  </ul>
                </div>
                <div className="menu-column-right">
                  <ul>
                    <li><p><strong>CAIPIROSKA MORANGO ... €7,00</strong><br /><i>Vodka, lime, strawberry syrup.</i></p></li>
                    <li><p><strong>CAIPIRINHA ... €7,00</strong><br /><i>Cachaca 51, lime, sugar syrup.</i></p></li>
                    <li><p><strong>JAPANESE ICE TEA ... €8,00</strong><br /><i>Gin, rum, vodka, sweet & sourtop, lemon soda</i></p></li>
                    <li><p><strong>ANGEL AZUL ... €8,00</strong><br /><i>Vodka, rum, triple sec, gin tequila, lemon juice, blue curasao.</i></p></li>
                    <li><p><strong>LONG ISLAND ICE TEA ... €7,00</strong><br /><i>Vodka, rum, triple sec, gin, tequila, lemon juice, cola.</i></p></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row"></div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="menu-item-large">
                <h4><strong>* In accordance with the European Regulation 1169/2011, "food allergens" we inform our dear customers that our dishes may contain:</strong></h4>
                <div className="menu-right">
                  <p><strong>Cereals and derivatives</strong><br />All gluten containing cereals such as wheat, rye, barley, oats, spelled, kamut are considered allergens. The list also esterides to their hybridized strains and derivative products.</p>
                  <p><strong>Crustaceans</strong><br />Proteins from, shrimps, scampi, crabs, lobsters, are considered allergens. obviously you must also avoid products that contain ingredients derived from shellfish.</p>
                  <p><strong>Egg</strong><br />Consider both cooked and raw allergens, and even if present in derivative products such as: egg pasta, boscotti, cakes, omelettes, mayonnaise, creams, breaded foods, flans, etc.</p>
                  <p><strong>Fish</strong><br />The allergy can occur for all types of fish and for derived products, with the exception of fish jelly used as a support for vitamin preparations or as a fining agent in beer and wine.</p>
                  <p><strong>Peanuts</strong><br />The main sources of allergens are derived products such as peanut oil, peanut butter, peanut flour, peanut milk used as an ingredient for creams, snacks, nougats, etc.</p>
                  <p><strong>Soy</strong><br />Protein sources of allergies are present in all soy based products, with the exception of: refined soybean oil and fat, natural mixed tocopherols, natural D alpha tocopherol, natural D alpha tocopherol acetate, natural soy based D alpha tocopherol, vegetable oils derived from phytosterols and phytosterols esters based on soy.</p>
                  <p><strong>Milk</strong><br />And products based on milk or lactose, except whey used for the manufacture of alcoholic distillates and milk.</p>
                  <p><strong>Nuts</strong><br />Namely almonds hazelnuts, walnuts, cashew nuts, pecans, Brazil nuts, pistachios, macadamia nuts and all products derived therefrom, except those used for the manufacture of alcoholic distillates.</p>
                  <p><strong>Celery</strong><br />Whether present in pieces or in derivative products such as preparations for soups, sauces and vegetable concentrates.</p>
                  <p><strong>Mustard</strong><br />Allergen that can be found among the main ingredients of sauces and condiments and especially in mustard.</p>
                  <p><strong>Sesame</strong><br />Whole seeds are often used for making bread, but traces of sesame are often found in some types of flours.</p>
                  <p><strong>Sulfur dioxide and sulphites</strong><br />Only if in concentrations higher than 10 mg / kg or 10 mg / 1 expressed as SO2 (used as preservatives) they are found in canned fish products, pickled foods, foods in oil and brine, jams, vinegar, dried mushrooms , soft drinks and fruit juices.</p>
                  <p><strong>Lupins</strong><br />Now present in many vegan foods, in the form of roasts, salamis, flours and similar based on this legume, rich in proteins.</p>
                  <p><strong>Clams</strong><br />Present in dishes based on canestrello, razor clam, scallop, sea date, fasolaro, garagolo, snail, mussel, murex, oyster, limpet, sea truffle and clam, or in their derivatives.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu;